// 属地大屏
import request from '../request.js';
// 专项行动
export function planSpecialList(data) {
    return request({
        url:"module-web/statistics/bigScreenPlanSpecialList",
        method:"post",
        data
    })
}
// 大屏有害信息数量统计
export function harmfulDataPlatformCount() {
    return request({
        url:"module-web/statistics/harmfulDataPlatformCount",
        method:"get"
    })
}
// 大屏有害信息今日数量统计
export function harmfulDataPlatformTodayCount() { 
    return request({
        url:"module-web/statistics/harmfulDataPlatformCountToday",
        method:"get"
    })
}
// 發佈內容統計
export function publishDataStat(statType) {
    return request({
        url:`module-web/statistics/publishDataStat?statType=${statType}`,
        method:"get"
    })
}
// 错误表述日期直方图统计
export function misreTimeDistribution(data) {
    return request({
        url:"module-web/statistics/misreTimeDistribution",
        method:"post",
        data
    })
}


// 大屏统计
export function totalStat() {
    return request({
        url:`module-web/statistics/totalStat`,
        method:"get"
    })
}