<template>
  <div class="time-tag " style="position:initial;">
    <div v-for="(item,inx) in dataList" :key="inx" :class="{
                tag: true,
                'tag-right': true,
                'tag-right-active': tagActive == (inx+1),
              }" @click="tagCheng((inx + 1))">
      {{item}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => ['今日', '本周', '本月', '本年']
    },
    tabIndex: Number,
    Active: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      tagActive: this.Active
    }
  },
  mounted() {
    this.tagCheng(this.Active)
  },
  methods: {
    tagCheng(dateType) {
      if (this.tagActive == dateType) return
      this.tagActive = dateType
      this.$emit('setTabFn', dateType, this.tabIndex)
    }
  }
}
</script>

<style scoped>
.tag {
  width: 0.54rem;
  height: 0.21rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  font-size: 0.12rem;
  color: #ffffff;
  text-align: center;
  line-height: 0.21rem;
  cursor: pointer;
}

.time-tag {
  position: absolute;
  right: 0.14rem;
  top: 0.2rem;
  display: flex;
  align-items: center;
  z-index: 1;
}

.tag-right {
  background-image: url(../assets/img/views/online/right.png);
}

.tag-right-active {
  background-image: url(../assets/img/views/online/right-active.png);
}
</style>