<template>
  <div :class="{ 'onfullScreen': off, 'unfullScreen': !off }" @click="fullScreen">
    <span class="fullScreen-text" v-if="off">全屏</span>
    <span class="fullScreen-text" v-if="!off">退出全屏</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      off: true
    };
  },
  methods: {
    fullScreen() {
      if (this.off) {
        document.documentElement.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
      this.off = !this.off;
    }
  },
  created() {

  },
  mounted() { },
};
</script>

<style scoped>
.onfullScreen {
  width: 0.28rem;
  height: 0.28rem;
  background-image: url(../assets/img/views/all.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #0f274c;
  border-radius: 0.05rem;
  cursor: pointer;
  line-height: 0.28rem;
  overflow: hidden;
}

.onfullScreen:hover {
  width: 0.7rem;
  background-position: 0.1rem center;
  display: flex;
  padding-left: 0.3rem;
}
.unfullScreen{
  width: 0.28rem;
  height: 0.28rem;
  background-image: url(../assets/img/views/unall.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #0f274c;
  border-radius: 0.05rem;
  cursor: pointer;
  line-height: 0.28rem;
  overflow: hidden;
}
.unfullScreen:hover {
  width: 1rem;
  background-position: 0.1rem center;
  display: flex;
  padding-left: 0.3rem;
}
.fullScreen-text {
  font-size: 0.16rem;
  color: #fff;
}
</style>
