
import myEchartFn from '../index'
export function zhuzi(dataX,dataArr,{
   left,right,bottom,
   pictorialBar
}={}) {
   // dataArr = dataArr.map(v=>11000)
   // dataArr = [...dataArr,24,25,26,27,28,29]
   let interval= () => {
      let len = dataX.length;
      if(len < 12) {
         return 0
      }else if(len < 24) {
         return 1
      }else if(len < 33) {
         return 2
      }
      
    }
let option = {
    // backgroundColor: "#03213D",
    tooltip: {
       trigger: "axis",
       backgroundColor: "rgba(0,0,0,.6)",
       borderColor: "rgba(147, 235, 248, 0)",
       textStyle: {
          color: "#FFF",
       },
       ...myEchartFn.tooltipBg
    },
    grid: {
       left: left ||  "5%",
       top: "10%",
       right: right ||  "0%",
       bottom: bottom || "5%",
       containLabel:true
    },
      //   // 加这块地方重点！！！！！！！
      //   dataZoom: [
      //    //滑动条
      //    {
      //      xAxisIndex: 0, //这里是从X轴的0刻度开始
      //      show: false, //是否显示滑动条，不影响使用
      //      type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
      //      startValue: 1, // 从头开始。
      //      endValue: 1, // 一次性展示5个。
      //    },
      //  ],
    xAxis: {
       data:dataX,
      //   ["番茄", "土豆", "大豆", "芸豆", "青椒", "玉米", "油麦菜", "韭菜"],
       axisLine: {
          show: true, //隐藏X轴轴线
          lineStyle: {
             color: "#163a5f",
             width: 2,
          },
       },
       axisTick: {
          show: false, //隐藏X轴刻度
          alignWithLabel: true,
       },
       axisLabel: {
          show: true,
          textStyle: {
             color: "#BDD8FB", //X轴文字颜色
             fontSize: 12,
          },
          interval:interval(),
          formatter:(parent) =>  myEchartFn.axisLabelFormatter(parent,12)
       },
    },
    yAxis: [
       {
          type: "value",
          name: "",
          nameTextStyle: {
             color: "#BDD8FB",
             fontSize: 12,
          },
 
          splitLine: {
             show: false,
             lineStyle: {
                color: "rgba(255, 255, 255, 0.15)",
                type: 'dashed', // dotted 虚线
             },
          },
          axisTick: {
             show: false,
          },
          axisLine: {
             show: false, //隐藏X轴轴线
             lineStyle: {
                color: "#163a5f",
                width: 1,
             },
          },
          axisLabel: {
             show: true,
             textStyle: {
                color: "#BDD8FB",
                fontSize: 12,
             },
            //   formatter:(value)=> {
            //    return myEchartFn.formatNumber(value || 0)
            //  }
          },
       },
       {
          type: "value",
          name: "",
          nameTextStyle: {
             color: "#BDD8FB",
             fontSize: 12,
          },
          splitLine: {
             show: false,
             lineStyle: {
                width: 1,
                color: "#CED2DB",
             },
          },
          axisTick: {
             show: false,
          },
          axisLine: {
             show: false, //隐藏X轴轴线
             lineStyle: {
                color: "#163a5f",
                width: 2,
             },
          },
          axisLabel: {
             show: false,
             textStyle: {
                color: "#797A7F",
                fontSize: 12,
             },
          },
       },
    ],
    series: [
       {
          name: "舆情数",
          type: pictorialBar ||"bar",
          barWidth: pictorialBar ? 80 :20,
          symbol:pictorialBar? 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z':"",
          itemStyle: {
             color: {
                type: 'linear',
                x: 0,  //右
                y: 0,  //下
                x2: 0,  //左
                y2: 1,  //上
                colorStops: [
                   {
                      offset: 0.1, 
                      color: '#31BDF9' // 0% 处的颜色
                   },
                   {
                      offset: 1,
                      color: 'rgba(32, 89, 184,0)' // 100% 处的颜色
                   }
                ]
             },
             barBorderRadius: [0, 0, 0, 0],
          },
          label: {
             show: true,
             position: "top",
             distance: 0,
             color: "#FFFFFF",
             formatter: "{c}",
             formatter:(value)=> myEchartFn.getTextgeshi(value.data)
          },
          data:dataArr,
       },
    ],
 };



 return option;
}